import {
    ADD_ARTICLE_TO_CART,
    DELETE_CART_ITEM,
    DELETE_TEMP_TRANSACTION,
    RECEIVE_ORDERTYPES_FOR_ORDER,
    SET_ARTICLES_LOADING,
    SET_LOADING,
    SET_ORDER,
    SET_TRANSACTION,
    SET_ORDER_TYPE,
    TOGGLE_SEARCH_POPUP,
    UNSET_ORDER,
    UPDATE_CURRENT_ARTICLES,
    UPDATE_CURRENT_ARTICLE_GROUPS,
    UPDATE_CUSTOMER_ADDRESS_FOR_ORDER,
    UPDATE_CUSTOMER_FOR_ORDER,
    UNSET_CUSTOMER_FOR_ORDER,
    UPDATE_TRANSACTIONS,
    UPDATE_CART_SUM,
    UPDATE_ORDER_VALIDATION,
    UPDATE_SELECTED_ARTICLE_GROUP,
    CACHE_ARTICLE_GROUPS,
    OPEN_ARTICLE_DETAILS_POPUP,
    CLOSE_ARTICLE_DETAILS_POPUP,
    SET_SKIP_ARTICLE_DETAILS,
    INCREMENT_CART_ITEM_AMOUNT,
    DECREMENT_CART_ITEM_AMOUNT,
    DUPLICATE_CART_ITEM,
    UPDATE_ORDER_COMMENT,
    UPDATE_MANUAL_DELIVERY_TIME,
    UPDATE_REQUESTED_DELIVERY_TIME,
    UPDATE_ORDER_PAYMENT_TYPE,
    UPDATE_ORDER_DISCOUNT,
    UPDATE_CART_ITEM_PRICE,
    UPDATE_SELECTED_CART_ITEM_ID,
    UPDATE_ARTICLE_SIZE,
    UPDATE_ARTICLE_DEPENDENCIES_OF_TRANSACTION_LINE_ITEM,
    CACHE_ARTICLE_DEPENDENCY_GROUPS,
    ADD_ARTICLE_DEPENDENCY_TO_CART,
    UPDATE_SUB_ITEM_AMOUNT,
    REMOVE_SUB_ITEM,
    SET_SELECTED_DEPENDENCY_GROUP,
    UPDATE_TRANSACTION_LINE_ITEM_TOTAL_PRICE,
    UPDATE_MIN_ORDER_VALUE,
    UPDATE_MIN_ORDER_CHARGE,
    UPDATE_DELIVER_LUMP_SUM,
    UPDATE_PAYMENT_FEE,
    UPDATE_TOTAL_DISCOUNT,
    UPDATE_SUM_TO_PAY,
    UPDATE_CURRENT_WEIGHT,
    UPDATE_SUB_ITEM_PRICE,
    UPDATE_CART_ITEM_COMMENT,
    UPDATE_SUB_ITEM_COMMENT,
    UPDATE_ORDER_VALIDITY,
} from "../actions/orderManagementActions";
import { CACHE_ARTICLES } from "../actions/menucardActions/articleAction";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    isLoading: false,
    isArticlesLoading: false,
    tempOrder: {
        Transactions: [
            {
                TransactionDatas: {
                    TransactionLines: [],
                },
            },
        ],
        orderTypeId: null,
        TransactionBuyers: {
            transactionBuyerId: 0,
            customerId: null,
            name: "",
            buyerType: 0,
            vat_id_number: null,
            billingStreet: "",
            billingHousenumber: "",
            billingPostal_code: "",
            billingCity: "",
            billingCountry_code: "DEU",
            deliveryStreet: "",
            deliveryHousenumber: "",
            deliveryPostal_code: "",
            deliveryCity: "",
            deliveryCountry_code: "DEU",
            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryAddressId: null,
        },
    },
    orderValidation: { fullfilled: true },
    minOrderValue: 0,
    minOrderCharge: 0,
    deliverLumpSum: 0,
    paymentFee: 0,
    totalDiscount: 0,
    sumToPay: 0,
    transactionSum: 0,
    orderTypes: [],
    rootArticleGroups: [],
    selectedArticleGroup: null,
    selectedCartItemId: null,
    articleGroups: [],
    articles: [],
    isSearchPopupOpen: false,
    selectedOrderType: false,
    cachedArticleGroups: {},
    cachedArticles: {},
    cachedArticleDependencyGroups: {},
    isOrderValid: false,
    validationErrors: [],
};

const ensureOrderStructure = (tempOrder) => ({
    ...tempOrder,
    Transactions: tempOrder.Transactions || [{ TransactionDatas: { TransactionLines: [] } }],
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ORDER:
            return { ...state, tempOrder: { ...action.payload, OrderDetails: action.payload.OrderDetails || [] } };
        case UNSET_ORDER:
            return initialState;
        case UPDATE_TRANSACTIONS:
            return { ...state, tempOrder: { ...state.tempOrder, CartItems: action.payload } };
        case SET_SELECTED_DEPENDENCY_GROUP:
            return { ...state, selectedDependencyGroup: action.payload };
        case ADD_ARTICLE_TO_CART:
            const safeState = {
                ...state,
                tempOrder: ensureOrderStructure(state.tempOrder || {}),
            };
            const currentTransactionLines = safeState.tempOrder.Transactions[0].TransactionDatas.TransactionLines;
            return {
                ...safeState,
                tempOrder: {
                    ...safeState.tempOrder,
                    Transactions: [
                        {
                            ...safeState.tempOrder.Transactions[0],
                            TransactionDatas: {
                                ...safeState.tempOrder.Transactions[0].TransactionDatas,
                                TransactionLines: [
                                    ...currentTransactionLines,
                                    {
                                        TransactionLineItems: action.payload,
                                    },
                                ],
                            },
                        },
                    ],
                },
            };
        case ADD_ARTICLE_DEPENDENCY_TO_CART:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.selectedCartItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              other_TransactionLineItems: [
                                                  ...line.TransactionLineItems.other_TransactionLineItems,
                                                  action.payload.transactionLineItem,
                                              ],
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_SUB_ITEM_AMOUNT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              other_TransactionLineItems:
                                                  line.TransactionLineItems.other_TransactionLineItems.map((subItem) =>
                                                      subItem.transactionLineItemId === action.payload.subItemId
                                                          ? {
                                                                ...subItem,
                                                                quantity: subItem.quantity + action.payload.amount,
                                                            }
                                                          : subItem
                                                  ),
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case REMOVE_SUB_ITEM:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              other_TransactionLineItems:
                                                  line.TransactionLineItems.other_TransactionLineItems.filter(
                                                      (subItem) =>
                                                          subItem.transactionLineItemId !== action.payload.subItemId
                                                  ),
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_ARTICLE_SIZE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              articleSizeId: action.payload.articleSize.articleSizesId,
                                              price_per_unit:
                                                  action.payload.articleSize.Prices.find(
                                                      (p) => p.orderTypesId === state.tempOrder.orderTypeId
                                                  )?.price ?? 0,
                                              ArticleSizes: {
                                                  ...line.TransactionLineItems.ArticleSizes,
                                                  Sizes: {
                                                      ...line.TransactionLineItems.ArticleSizes.Sizes,
                                                      sizesId: action.payload.articleSize.Sizes.sizesId,
                                                      name: action.payload.articleSize.Sizes.name,
                                                  },
                                              },
                                              other_TransactionLineItems:
                                                  line.TransactionLineItems.other_TransactionLineItems.map(
                                                      (subItem) => ({
                                                          ...subItem,
                                                          articleSizeId: subItem.Articles.ArticleSizes.find(
                                                              (size) =>
                                                                  size.Sizes.sizesId ===
                                                                  action.payload.articleSize.Sizes.sizesId
                                                          )?.articleSizesId,
                                                          price_per_unit:
                                                              subItem.price_per_unit === 0
                                                                  ? 0
                                                                  : subItem.Articles.ArticleSizes.find(
                                                                        (size) =>
                                                                            size.Sizes.sizesId ===
                                                                            action.payload.articleSize.Sizes.sizesId
                                                                    )?.Prices.find(
                                                                        (p) =>
                                                                            p.orderTypesId ===
                                                                            state.tempOrder.orderTypeId
                                                                    )?.price ?? subItem.price_per_unit,
                                                          ArticleSizes: {
                                                              ...subItem.ArticleSizes,
                                                              Sizes: {
                                                                  ...subItem.ArticleSizes.Sizes,
                                                                  sizesId: action.payload.articleSize.Sizes.sizesId,
                                                                  name: action.payload.articleSize.Sizes.name,
                                                              },
                                                          },
                                                      })
                                                  ),
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_SUB_ITEM_PRICE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              other_TransactionLineItems:
                                                  line.TransactionLineItems.other_TransactionLineItems.map((subItem) =>
                                                      subItem.transactionLineItemId === action.payload.subItemId
                                                          ? { ...subItem, price_per_unit: action.payload.newPrice }
                                                          : subItem
                                                  ),
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_CURRENT_WEIGHT:
            return {
                ...state,
                currentWeight: action.payload,
            };
        case DUPLICATE_CART_ITEM:
            const originalItem = state.tempOrder.Transactions[0].TransactionDatas.TransactionLines.find(
                (line) => line.TransactionLineItems.transactionLineItemId === action.payload
            );
            if (!originalItem) return state;
            const duplicatedItem = {
                ...originalItem,
                TransactionLineItems: {
                    ...originalItem.TransactionLineItems,
                    transactionLineItemId: uuidv4(),
                    other_TransactionLineItems: originalItem.TransactionLineItems.other_TransactionLineItems.map(
                        (subItem) => ({
                            ...subItem,
                            transactionLineItemId: uuidv4(),
                        })
                    ),
                },
            };
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: [...transaction.TransactionDatas.TransactionLines, duplicatedItem],
                        },
                    })),
                },
            };
        case SET_TRANSACTION:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    CartItems:
                        state.tempOrder.CartItems?.map((transaction) => {
                            if (transaction.cartItemId === action.payload.cartItemId) {
                                return {
                                    ...transaction,
                                    transactionId: action.payload.transactionId,
                                    TotalSalesPrice: action.payload.TotalSalesPrice,
                                    size: action.payload.size,
                                };
                            }
                            return transaction;
                        }) || [],
                },
            };
        case UPDATE_ORDER_VALIDATION:
            return { ...state, orderValidation: action.payload };
        case UPDATE_MIN_ORDER_VALUE:
            return {
                ...state,
                minOrderValue: action.payload,
            };
        case UPDATE_MIN_ORDER_CHARGE:
            return {
                ...state,
                minOrderCharge: action.payload,
            };
        case UPDATE_DELIVER_LUMP_SUM:
            return {
                ...state,
                deliverLumpSum: action.payload,
            };
        case UPDATE_PAYMENT_FEE:
            return {
                ...state,
                paymentFee: action.payload,
            };
        case UPDATE_TOTAL_DISCOUNT:
            return {
                ...state,
                totalDiscount: action.payload,
            };
        case UPDATE_SUM_TO_PAY:
            return {
                ...state,
                sumToPay: action.payload,
            };
        case UPDATE_ARTICLE_DEPENDENCIES_OF_TRANSACTION_LINE_ITEM:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) => {
                                if (
                                    line.TransactionLineItems.transactionLineItemId ===
                                    action.payload.transactionLineItemId
                                ) {
                                    return {
                                        ...line,
                                        TransactionLineItems: {
                                            ...line.TransactionLineItems,
                                            dependencyArticleGroups:
                                                action.payload.articleDependencyGroups.dependencyArticleGroups,
                                            dependencyGroups: action.payload.articleDependencyGroups.dependencyGroups,
                                        },
                                    };
                                }
                                return line;
                            }),
                        },
                    })),
                },
            };
        case UPDATE_CUSTOMER_FOR_ORDER:
            const customerData = action.payload;
            const defaultAddress =
                customerData.Address ||
                (customerData.Addresses &&
                    customerData.Addresses.find((address) => address.isDefaultDeliveryAddress === true));

            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,

                    TransactionBuyers: {
                        ...(state.tempOrder.TransactionBuyers || {}),
                        Customer: {
                            ...customerData,
                            Address: defaultAddress,
                        },
                        customerId: customerData.customersId,
                        name: `${customerData.firstName} ${customerData.lastName}`.trim(),
                        billingStreet: customerData.billingStreet || defaultAddress?.Street?.name || "",
                        billingHousenumber: customerData.billingHousenumber || defaultAddress?.housenumber || "",
                        billingPostal_code: customerData.billingZipcode || defaultAddress?.Street?.City?.zipCode || "",
                        billingCity: customerData.billingCity || defaultAddress?.Street?.City?.name || "",
                        deliveryStreet: defaultAddress?.Street?.name || defaultAddress?.street || "",
                        deliveryHousenumber: defaultAddress?.housenumber || "",
                        deliveryPostal_code: defaultAddress?.Street?.City?.zipCode || defaultAddress?.zipCode || "",
                        deliveryCity: defaultAddress?.Street?.City?.name || defaultAddress?.city || "",
                        deliveryFirstName: defaultAddress?.firstName || customerData.firstName || "",
                        deliveryLastName: defaultAddress?.lastName || customerData.lastName || "",
                        deliveryAddressId: defaultAddress?.addressId || null,
                        transactionBuyerId:
                            customerData.transactionBuyerId ??
                            state.tempOrder.TransactionBuyers?.transactionBuyerId ??
                            0,
                        buyerType: customerData.buyerType ?? state.tempOrder.TransactionBuyers?.buyerType ?? 0,
                        vat_id_number:
                            customerData.vat_id_number ?? state.tempOrder.TransactionBuyers?.vat_id_number ?? null,
                    },
                },
            };
        case UNSET_CUSTOMER_FOR_ORDER:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    TransactionBuyers: {
                        ...state.tempOrder.TransactionBuyers,
                        Customer: null,
                        Customers: null,
                    },
                },
            };
        case UPDATE_CUSTOMER_ADDRESS_FOR_ORDER:
            const addressData = action.payload;
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,

                    TransactionBuyers: {
                        ...(state.tempOrder.TransactionBuyers || {}),
                        Customer: {
                            ...state.tempOrder.TransactionBuyers?.Customer,
                            Address: addressData,
                        },
                        deliveryStreet: addressData.Street?.name || "",
                        deliveryHousenumber: addressData.housenumber || "",
                        deliveryPostal_code: addressData.Street?.City?.zipCode || "",
                        deliveryCity: addressData.Street?.City?.name || "",
                        deliveryFirstName: addressData.firstName || "",
                        deliveryLastName: addressData.lastName || "",
                        deliveryAddressId: addressData.addressId || null,
                        transactionBuyerId: state.tempOrder.TransactionBuyers?.transactionBuyerId ?? 0,
                        buyerType: state.tempOrder.TransactionBuyers?.buyerType ?? 0,
                        vat_id_number: state.tempOrder.TransactionBuyers?.vat_id_number ?? null,
                    },
                },
            };

        case DELETE_CART_ITEM:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.filter(
                                (line) => line.TransactionLineItems.transactionLineItemId !== action.payload
                            ),
                        },
                    })),
                },
            };
        case DELETE_TEMP_TRANSACTION:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    CartItems: [
                        ...state.tempOrder.CartItems.filter((transaction) => {
                            return transaction.cartItemId !== action.payload;
                        }),
                    ],
                },
            };

        case RECEIVE_ORDERTYPES_FOR_ORDER:
            return { ...state, orderTypes: action.payload.filter((orderType) => !orderType.isEshop) };
        case SET_ORDER_TYPE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    orderTypeName: action.payload.name,
                    orderTypeId: action.payload.orderTypesId,
                },
                selectedOrderType: action.payload,
            };
        case SET_ARTICLES_LOADING:
            return { ...state, isArticlesLoading: action.payload };
        case UPDATE_SELECTED_ARTICLE_GROUP:
            return { ...state, selectedArticleGroup: action.payload };
        case UPDATE_SELECTED_CART_ITEM_ID:
            return { ...state, selectedCartItemId: action.payload };
        case UPDATE_CURRENT_ARTICLE_GROUPS:
            return { ...state, articleGroups: action.payload };
        case UPDATE_CURRENT_ARTICLES:
            const articlesWithForFree = action.payload.articles.map((article) => ({
                ...article,
                forFree: action.payload.forFree,
            }));
            return { ...state, articles: articlesWithForFree };
        case TOGGLE_SEARCH_POPUP:
            return { ...state, isSearchPopupOpen: action.payload };
        case CACHE_ARTICLE_GROUPS:
            return {
                ...state,
                cachedArticleGroups: {
                    ...state.cachedArticleGroups,
                    [action.payload.parentId]: action.payload.articleGroups,
                },
            };
        case CACHE_ARTICLES:
            return {
                ...state,
                cachedArticles: {
                    ...state.cachedArticles,
                    [action.payload.articleGroupId]: action.payload.articles,
                },
            };
        case CACHE_ARTICLE_DEPENDENCY_GROUPS:
            return {
                ...state,
                cachedArticleDependencyGroups: {
                    ...state.cachedArticleDependencyGroups,
                    [action.payload.articlesId]: action.payload.articleDependencyGroups,
                },
            };
        case UPDATE_ORDER_COMMENT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    comment: action.payload,
                },
            };
        case UPDATE_MANUAL_DELIVERY_TIME:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    manualDeliveryTimeMin: action.payload.manualDeliveryTimeMin,
                    manualDeliveryTimeMax: action.payload.manualDeliveryTimeMax,
                },
            };
        case UPDATE_REQUESTED_DELIVERY_TIME:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    requestedDeliveryTime: action.payload,
                },
            };
        case INCREMENT_CART_ITEM_AMOUNT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              quantity: line.TransactionLineItems.quantity + 1,
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case DECREMENT_CART_ITEM_AMOUNT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload &&
                                line.TransactionLineItems.quantity > 1
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              quantity: line.TransactionLineItems.quantity - 1,
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };

        case UPDATE_ORDER_PAYMENT_TYPE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    paymentTypesId: action.payload,
                },
            };
        case UPDATE_ORDER_DISCOUNT: // 5 percentageDiscount, 1 amountDiscount
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    DSFinVKOrderDiscount: (() => {
                        const currentDiscounts = state.tempOrder.DSFinVKOrderDiscount || [];
                        const updateDiscount = (id, value) => {
                            const existingIndex = currentDiscounts.findIndex((d) => d.orderDiscountTypesId === id);
                            if (existingIndex !== -1) {
                                if (value === 0) {
                                    currentDiscounts.splice(existingIndex, 1);
                                } else {
                                    currentDiscounts[existingIndex].value = value;
                                }
                            } else if (value !== 0) {
                                currentDiscounts.push({ orderDiscountTypesId: id, value, isMonetary: id === 1 });
                            }
                        };

                        updateDiscount(5, action.payload.percentageDiscount);
                        updateDiscount(1, action.payload.valueDiscount);
                        return currentDiscounts;
                    })(),
                },
            };
        case UPDATE_CART_ITEM_PRICE:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions:
                        state.tempOrder.Transactions &&
                        state.tempOrder.Transactions.map((transaction) => ({
                            ...transaction,
                            TransactionDatas: {
                                ...transaction.TransactionDatas,
                                TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                    line.TransactionLineItems.transactionLineItemId ===
                                    action.payload.transactionLineItemId
                                        ? {
                                              ...line,
                                              TransactionLineItems: {
                                                  ...line.TransactionLineItems,
                                                  price_per_unit: action.payload.newPrice,
                                              },
                                          }
                                        : line
                                ),
                            },
                        })),
                },
            };
        case UPDATE_TRANSACTION_LINE_ITEM_TOTAL_PRICE:
            return {
                ...state,
                tempOrder: ensureOrderStructure({
                    ...state.tempOrder,
                    Transactions: (state.tempOrder.Transactions || []).map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: (transaction.TransactionDatas?.TransactionLines || []).map((line) =>
                                line.TransactionLineItems?.transactionLineItemId ===
                                action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              total_price: action.payload.totalPrice,
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                }),
            };
        case UPDATE_CART_ITEM_COMMENT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              comment: action.payload.comment,
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_SUB_ITEM_COMMENT:
            return {
                ...state,
                tempOrder: {
                    ...state.tempOrder,
                    Transactions: state.tempOrder.Transactions.map((transaction) => ({
                        ...transaction,
                        TransactionDatas: {
                            ...transaction.TransactionDatas,
                            TransactionLines: transaction.TransactionDatas.TransactionLines.map((line) =>
                                line.TransactionLineItems.transactionLineItemId === action.payload.transactionLineItemId
                                    ? {
                                          ...line,
                                          TransactionLineItems: {
                                              ...line.TransactionLineItems,
                                              other_TransactionLineItems:
                                                  line.TransactionLineItems.other_TransactionLineItems.map((subItem) =>
                                                      subItem.transactionLineItemId === action.payload.subItemId
                                                          ? { ...subItem, comment: action.payload.comment }
                                                          : subItem
                                                  ),
                                          },
                                      }
                                    : line
                            ),
                        },
                    })),
                },
            };
        case UPDATE_ORDER_VALIDITY:
            return {
                ...state,
                isOrderValid: action.payload.isValid,
                validationErrors: action.payload.errors,
            };
        default:
            return {
                ...state,
                tempOrder: ensureOrderStructure(state.tempOrder || {}),
            };
    }
};

import React, { useState } from 'react';
import { Popup, RadioGroup, Button, TextBox, DateBox } from 'devextreme-react';
import './exportModal.scss';

const ExportModal = ({ visible, onHide, onExport }) => {
    const exportTypes = [
        { text: 'Kompletter Export erzeugen', value: 'complete' },
        { text: 'Gefilterter Export', value: 'filtered' }
    ];
    const [exportType, setExportType] = useState(exportTypes[0]);
    const [formData, setFormData] = useState({
        first_transaction_number: '',
        last_transaction_number: '',
        start_date: null,
        start_time: '',
        end_date: null,
        end_time: '',
        max_number_records: ''
    });

    const handleExport = () => {
        let exportData = {};

        if (exportType.value === 'filtered') {
            exportData = {
                first_transaction_number: formData.first_transaction_number || undefined,
                last_transaction_number: formData.last_transaction_number || undefined,
                start_date: formData.start_date,
                start_time: formData.start_time,
                end_date: formData.end_date,
                end_time: formData.end_time,
                max_number_records: formData.max_number_records || undefined
            };
        }

        onExport(exportData);
        onHide();
    };

    return (
        <Popup
            visible={visible}
            onHiding={onHide}
            title="Trigger Export (Export für 1 TSE IDs)"
            showCloseButton={true}
            width={500}
            height="auto"
            className="export-popup"
            position={{
                my: 'center',
                at: 'center',
                of: window,
                collision: 'fit'
            }}
        >
            <div className="export-modal-content">
                <p className="export-description">
                    Definieren Sie die zu exportierende Sektion oder erzeugen Sie einen kompletten Export aller Log-Nachrichten.
                </p>

                <RadioGroup
                    items={exportTypes}
                    defaultValue={exportType}
                    onValueChanged={e => setExportType(e.value)}
                />

                {exportType.value === 'filtered' && (
                    <div className="filter-form">
                        <div className="form-group">
                            <label>Erste Transaktions Nr.</label>
                            <TextBox
                                value={formData.first_transaction_number}
                                onValueChanged={e => setFormData({...formData, first_transaction_number: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Letzte Transaktions Nr.</label>
                            <TextBox
                                value={formData.last_transaction_number}
                                onValueChanged={e => setFormData({...formData, last_transaction_number: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Startdatum</label>
                            <DateBox
                                type="date"
                                displayFormat="dd.MM.yyyy"
                                value={formData.start_date}
                                onValueChanged={e => setFormData({...formData, start_date: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Startzeit</label>
                            <TextBox
                                mask="00:00"
                                value={formData.start_time}
                                onValueChanged={e => setFormData({...formData, start_time: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Enddatum</label>
                            <DateBox
                                type="date"
                                displayFormat="dd.MM.yyyy"
                                value={formData.end_date}
                                onValueChanged={e => setFormData({...formData, end_date: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Endzeit</label>
                            <TextBox
                                mask="00:00"
                                value={formData.end_time}
                                onValueChanged={e => setFormData({...formData, end_time: e.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Maximalanzahl an Log-Nachrichten</label>
                            <TextBox
                                value={formData.max_number_records}
                                onValueChanged={e => setFormData({...formData, max_number_records: e.value})}
                            />
                        </div>
                    </div>
                )}

                <div className="modal-buttons">
                    <Button
                        text="ABBRECHEN"
                        onClick={onHide}
                    />
                    <Button
                        text="EXPORT"
                        type="default"
                        onClick={handleExport}
                        className="dx-button-success"
                    />
                </div>
            </div>
        </Popup>
    );
};

export default ExportModal;
class Config {
    constructor() {
        this.backendHost = "/b";
        this.currencyFormat = "0[.]00 $";
        this.grammagesFormat = "0[.]00";
        this.selectedTheme = "Bios"; // möglich ["Bios","Rewe","Sandbox"] default="Bios"
        this.selectedImpressum = "FoodGenius"; // möglich ["FoodGenius","Bios"] default="FoodGenius"
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
        //this.apiUrl = "http://localhost:7071/cashregister";
        this.apiUrl =
            process.env.NODE_ENV === "development"
                ? "http://localhost:7071/cashregister"
                : "https://functions.foodgenius.de/cashregister";
        this.biosClientToken =
            process.env.NODE_ENV === "development" && (!window._env_ || !window._env_.REACT_APP_BIOS_CLIENT_TOKEN)
                ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJLZXlWYXVsdE5hbWUiOiJCSU9TIiwiUmVhbG0iOiJkZXYifQ.6QMpHGrDwMfMxWddGHtvoLUxrUGb08DJ3HsCuFjzGC0"
                : window._env_?.REACT_APP_BIOS_CLIENT_TOKEN ||
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJLZXlWYXVsdE5hbWUiOiJCSU9TIiwiUmVhbG0iOiJkZXYifQ.6QMpHGrDwMfMxWddGHtvoLUxrUGb08DJ3HsCuFjzGC0";
    }
}

export default Config;

import Config from "../Config";
import { authHeader, authHeaderForAzure, handleResponse } from "../helpers/requestHelpers";

const config = new Config();

export function fetchTse(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + `/getTse/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function fetchTseClients(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + `/getTseClients/${tseId}`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkApiKeyAndSecret() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Tse/checkApiKeyAndSecret", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createNewTse() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + "/createTse", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function reinitializeCurrentTse() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + "/reinitializeTse", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function createTseClient() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + "/createTseClient", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function exportTse(data, tseId) {
    const requestOptions = {
        method: "PUT",
        headers: authHeaderForAzure(),
        body: JSON.stringify(data),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + `/exportTse/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function getAllTseExports(tseId) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + `/getAllTseExports/${tseId}`, requestOptions).then((response) => handleResponse(response));
    };
}

export function getTseExportById(exportUUID) {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };

    return function (dispatch) {
        return fetch(config.apiUrl + `/getTseExport/${exportUUID}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    return handleResponse(response);
                }
                return response.blob();
            })
    };
}

export function getAllTse() {
    const requestOptions = {
        method: "GET",
        headers: authHeaderForAzure(),
    };
    return function (dispatch) {
        return fetch(config.apiUrl + "/getAllTse", requestOptions).then((response) => handleResponse(response));
    };
}

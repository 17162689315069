const initialState = {
    paymentTypes: [],
};

export default function paymentTypesReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_PAYMENT_TYPES":
            return {
                ...state,
                paymentTypes: action.payload,
            };
        default:
            return state;
    }
}

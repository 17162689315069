import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import { withTranslation } from "react-i18next";
import {
    fetchTseClients,
    fetchTse,
    checkApiKeyAndSecret,
    reinitializeCurrentTse,
    createNewTse,
    createTseClient,
    exportTse,
    getAllTseExports,
    getTseExportById,
    getAllTse,
} from "../../../../../actions/tseAction";
import store from "../../../../../store";
import { Button, LoadPanel, SelectBox, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import "./tse.scss";
import ExportModal from './exportModal';

const Tse = ({ t }) => {
    const [tse, setTse] = useState({});
    const [tseClients, setTseClients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [exports, setExports] = useState([]);
    const [allTses, setAllTses] = useState([]);
    const [selectedTseId, setSelectedTseId] = useState(null);

    const loadData = () => {
        store.dispatch(getAllTse()).then((response) => {
            setAllTses(response.data || []);
            if (response.data?.length && !selectedTseId) {
                setSelectedTseId(response.data[response.data.length - 1]._id);
            }
        });

        if (selectedTseId) {
            store.dispatch(fetchTse(selectedTseId)).then((response) => {
                setTse(response);
            });
            store.dispatch(fetchTseClients(selectedTseId)).then((response) => {
                setTseClients(response.data || []);
            });
            loadExports();
        }
    };

    const loadExports = () => {
        store.dispatch(getAllTseExports(selectedTseId)).then((response) => {
            setExports(response.data || []);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        store.dispatch(checkApiKeyAndSecret()).then((response) => {
            if (response.apiCredentialsAvailable) {
                loadData();
            }
            setIsLoading(false);
        });
    }, [selectedTseId]);

    const createTse = () => {
        setIsLoading(true);
        store.dispatch(createNewTse()).then((response) => {
            loadData();
            setIsLoading(false);
        });
    };

    const reinitializeTse = () => {
        setIsLoading(true);
        store.dispatch(reinitializeCurrentTse()).then((response) => {
            loadData();
            setIsLoading(false);
        });
    };

    const createClient = () => {
        setIsLoading(true);
        store.dispatch(createTseClient()).then((response) => {
            loadData();
            setIsLoading(false);
        });
    };

    const handleExport = (exportData) => {
        store.dispatch(exportTse(exportData, selectedTseId)).then(() => {
            loadExports();
        });
    };

    const handleDownloadExport = (exportId) => {
        store.dispatch(getTseExportById(exportId)).then((response) => {
            const blob = new Blob([response], { type: 'application/x-tar' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export-${exportId}.tar`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
    };

    const getSortedExports = () => {
        return [...exports].sort((a, b) => b.time_request - a.time_request);
    };

    const hasPendingExport = () => {
        return exports.some(exp => exp.state === 'PENDING' || exp.state === 'WORKING');
    };

    const formatTseLabel = (tse) => {
        if (!tse) return '';
        const date = new Date(tse.time_creation * 1000).toLocaleString();
        return `TSE ${tse._id} (${date}) - ${tse.state}`;
    };

    return (
        <div className="tse-container">
            <LoadPanel visible={isLoading} />
            <div className="tse-selector">
                <SelectBox
                    dataSource={[...allTses].sort((a, b) => b.time_creation - a.time_creation)}
                    displayExpr={formatTseLabel}
                    valueExpr="_id"
                    value={selectedTseId}
                    onValueChanged={(e) => setSelectedTseId(e.value)}
                    width="100%"
                />
                <Button
                    text="Neue TSE erzeugen"
                    type="default"
                    onClick={createTse}
                    className="create-tse-button"
                />
            </div>
            {tse && (
                <>
                    <div className="tse-grid">
                        <div className="info-card id">
                            <h3>TSE ID</h3>
                            <div className="content">
                                <span>{tse._id}</span>
                                <div className="button-group">
                                    <Button
                                        text="EXPORT AUSLÖSEN"
                                        type="default"
                                        stylingMode="contained"
                                        className="dx-button-success"
                                        onClick={() => setShowExportModal(true)}
                                        disabled={hasPendingExport()}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="info-card status">
                            <h3>Status</h3>
                            <div className="content">
                                <span>{tse.state}</span>
                                <div className="button-group">
                                    <Button
                                        text="TSE DEAKTIVIEREN"
                                        type="default"
                                        disabled={tse.state !== 'INITIALIZED'}
                                        stylingMode="contained"
                                        className="dx-button-success"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="info-card clients">
                            <h3>Registrierte Clients</h3>
                            <div className="content">
                                <span>{tse.number_registered_clients}</span>
                            </div>
                        </div>

                        <div className="info-card signature">
                            <h3>Signaturzähler</h3>
                            <div className="content">
                                <span>{tse.signature_counter}</span>
                            </div>
                        </div>

                        <div className="info-card dates">
                            <h3>Initiierungsdatum</h3>
                            <span>{tse.time_init ? new Date(tse.time_init * 1000).toLocaleString() : 'Nicht initialisiert'}</span>
                        </div>
                    </div>

                    <div className="tse-grid">
                        <div className="info-card certificate">
                            <h3>Serien Nr. des Zertifikats</h3>
                            <div className="content">
                                <span>{tse.serial_number}</span>
                            </div>
                        </div>

                        <div className="info-card description">
                            <h3>Beschreibung</h3>
                            <span>{tse.description}</span>
                        </div>

                        <div className="info-card dates">
                            <h3>Erstellungsdatum</h3>
                            <span>{tse.time_creation ? new Date(tse.time_creation * 1000).toLocaleString() : '-'}</span>
                        </div>
                    </div>

                    <div className="clients-section">
                        <h3>Clients</h3>
                        <DataGrid
                            dataSource={tseClients}
                            showBorders={true}
                            columnAutoWidth={true}
                        >
                            <Column dataField="_id" caption="ID" />
                            <Column dataField="_version" caption="Version" />
                            <Column dataField="state" caption="Status" />
                            <Column dataField="serial_number" caption="Serien Nr." />
                            <Column
                                dataField="time_creation"
                                caption="Erstellungsdatum"
                                dataType="datetime"
                                calculateCellValue={(rowData) => new Date(rowData.time_creation * 1000)}
                            />
                        </DataGrid>
                    </div>

                    <div className="exports-section">
                        <div className="section-header">
                            <h3>Exports</h3>
                            <Button
                                text="AKTUALISIEREN"
                                type="default"
                                stylingMode="contained"
                                onClick={loadExports}
                            />
                        </div>
                        <table className="exports-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Status</th>
                                    <th>Angefordert am</th>
                                    <th>Start</th>
                                    <th>Ende</th>
                                    <th>Fehler</th>
                                    <th>Aktion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getSortedExports().map(exp => (
                                    <tr key={exp._id}>
                                        <td>{exp._id}</td>
                                        <td>{exp.state}</td>
                                        <td>{new Date(exp.time_request * 1000).toLocaleString()}</td>
                                        <td>{new Date(exp.time_start * 1000).toLocaleString()}</td>
                                        <td>{new Date(exp.time_end * 1000).toLocaleString()}</td>
                                        <td>{exp.exception || '-'}</td>
                                        <td>
                                            <Button
                                                text="HERUNTERLADEN"
                                                type="default"
                                                stylingMode="contained"
                                                className="dx-button-success"
                                                disabled={exp.state !== 'COMPLETED'} // Only enable for finished exports
                                                onClick={() => handleDownloadExport(exp._id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <ExportModal
                        visible={showExportModal}
                        onHide={() => setShowExportModal(false)}
                        onExport={handleExport}
                        tseId={tse._id}
                    />
                </>
            )}
            {!tse && (
                <Button height={50} text={"neue TSE erzeugen"} type="default" onClick={createNewTse} />
            )}
        </div>
    );
};

export default withTranslation(["dynamicTranslation"])(Tse);
